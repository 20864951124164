import React from "react"
import { Wide } from "../components/layout"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = ({data, location}) => {
  return (
    <Layout>
      <SEO title="Contact" path={location.pathname} />
      <div className="bg-red-600">
        <div className="max-w-3xl mx-auto py-16 px-4 sm:py-24">
          <h2 className="text-4xl font-sans font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">Contact</h2>
        </div>
      </div>
      <Wide>
        <div className="max-w-3xl m-auto px-4">
          <p>Vonk Fantasy</p>
          <p>Beatrijspad 18, 3813DM, Amersfoort</p>
          <p>033-2007422</p>
          <p>tom@vonkfantasy.nl</p>
          <p>KVK: 82067147</p>
          <p>BTW: NL003636759B49</p>
        </div>
      </Wide>
    </Layout>
  )
}

export default Contact;
